#maintenance .title {
    font-size: 22pt;
    font-weight: bold;
}
#maintenance .message {
    font-size: 14pt;
}

@media (max-width: 575px) {
    #maintenance .title {
        font-size: 16pt;
        font-weight: bold;
    }
    #maintenance .message {
        font-size: 10pt;
    }
}
#deposit {
    text-align: left !important;
}

#deposit .btn,#withdraw .btn {
    // border-radius: 5px !important;
}

#deposit .card {
    background-color: var(--theme-opacity-primary) !important;
}

#withdraw .form-withdraw {
    text-align: left !important;
}

#withdraw .btn-range .col-3 {
    padding-right: 0px !important;
}

/* transfer */
#transfer {
    min-height: 410px;
}
#transfer .nav.nav-pills {
    background-color: var(--theme-opacity-primary) !important;
    padding: 10px;
    border-radius: 50px;
}

#transfer .nav.nav-pills .nav-link {
    border-radius: 50px !important;
    color: var(--theme-opacity-color-primary);
    min-width: 30% !important;
}

#transfer .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    background-color:var(--theme-color-secondary) ;
    color: white;
}

#transfer .tab-content .card  {
    background-color: var(--theme-color-secondary) ;
    border-radius: 50px;
    text-align: left !important;    
}

#transfer .card {
    background-color: var(--theme-opacity-primary) !important;
    border: none;
}

.btn-spotlight-1 {
    font-family: "Prompt", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 30px 10px 30px;
    border-radius: 4px;
    transition: 0.5s;
    color: var(--theme-text-on-background);
    background: var(--theme-bg-btn-01);
    box-shadow: 0 8px 28px var(--theme-color-primary);
    width: 100%;
    margin-bottom: 10px;
    border: none !important;
}

.btn-spotlight-2 {
    font-family: 'Prompt', sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 30px 10px 30px;
    border-radius: 4px;
    transition: 0.5s;
    color: var(--theme-text-on-background);
    background: var(--theme-color-six);
    box-shadow: 0 8px 28px var(--theme-color-six);
    width: 100%;
    margin-bottom: 10px;
    border: none !important;
}
/*--------------------------------------------------------------
# wallet Section
--------------------------------------------------------------*/
#wallet {
    width: 100%;
    padding-top: 85px;
}
#wallet .wallet-card {
    background: var(--theme-gradient-primary);
    border-radius: 8px;
    padding: 10px 16px;
    color: var(--theme-text-on-background);
    display: grid;
    gap: 4px;
    width: 100%;
}
#wallet .btn-wallet {
    justify-content: space-between;
}
#wallet .btn-wallet button {
    width: 80%;
    margin-top: 10px;
}

#wallet .wallet-card .box-credit {
    border-right: 2px solid var(--theme-color-primary);
    margin-top: 10px;
}

#wallet .wallet-card .box-info{
    margin-top: 0px;
}
#wallet .wallet-card .box-info i.bi-award{
    color: var(--theme-color-five) !important;
}

#wallet .wallet-card .box-info .full-name{
    margin-top: 10px;
}

#wallet .loading {
    width: 25px;
    animation: 0.75s linear infinite spinner-border;
}


#credit section {
    background-color: none !important;
    background-image: none !important;
}


@media only screen and (max-width: 768px) {
    #wallet .wallet-card .box-credit {
        border-right: none;
        order: 2 !important;
    }
    #wallet .wallet-card .box-info{
        order: 1 !important;
        margin-bottom: 20px;
    }
}
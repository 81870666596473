.title-a {
    width: 100%;
    background-color: var(--theme-color-quinary);
    color: var(--theme-text-on-background);
    border: none;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    padding: 8px;
    text-align: -webkit-center;
}

.a-date {
    font-size: 0.8rem;
    background-color: var(--theme-color-light);
    width: 260px;
    color: var(--theme-color-dark);
    border: none;
    border-radius: 4px;
    margin: 8px;
    padding: 4px 8px;
}

.a-frist {
    width: 100%;
    background-color: var(--theme-color-seven);
    color: var(--theme-text-on-background);
    border: none;
    padding: 8px;
    text-align: -webkit-center;
}

.a-second {
    width: 100%;
    background-color: var(--theme-color-tertiary);
    color: var(--theme-text-on-background);
    border: none;
    padding: 8px;
    text-align: -webkit-center;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background-image: var(--theme-gradient-award) !important;
    margin-bottom: 10px;
}

.a-number {
    /* width: 32px; */
    min-width: 32px;
    max-width: 90px;
    height: 38px;
    border-radius: 4px;
    outline: none;
    padding: 0px;
    border: none;
    margin: 4px 2px;
    text-align: -webkit-center;
    font-weight: bolder;
    color: rgb(0, 0, 0);
    cursor: default !important;
}

.daterangepicker {
    color: black !important;
}

#about .btn {
    border-radius: 3px !important;
}

.btn-facebook {
    border-bottom-left-radius: 8px;
    width: 50%;
    float: left;
    background: var(--theme-gradient-facebook);
    cursor: pointer;
}

.btn-line {
    border-bottom-right-radius: 8px;
    width: 50%;
    float: left;
    background: var(--theme-gradient-line);
    cursor: pointer;
}
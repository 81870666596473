/* #play-history .bg-light-green {
    background-color: #15948b;
}

#play-history .bg-light-gray {
    background-color: #e0e0e0;
} */

#play-history .text-sm {
    font-size: small;

}

#play-history .btn {
    border-radius: 8px !important;

}

.bg-history-card {
    background-color: var(--theme-color-tertiary) ;
} 

.bg-head-card {
    background-color: var(--theme-color-tertiary) ;
    // color: $black;
} 

/*--------------------------------------------------------------
# login Section
--------------------------------------------------------------*/

#login {
    width: 100%;
    /* height: 80vh; */
    /* background: $white; */
    padding-top: 85px;
  
  }
  #login h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: var(--theme-color-primary);
  }
  #login h2 {
    color: var(--theme-text-out-background);
    margin: 10px 0 30px 0;
    font-size: 24px;
  }
  #login .btn-regis {
    font-family: 'Prompt', sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 30px 10px 30px;
    border-radius: 4px;
    transition: 0.5s;
    color: var(--theme-bg-light);
    background: var(--theme-color-six);
    box-shadow: 0 8px 28px var(--theme-color-six);
    width: 100%;
    margin-bottom: 10px;
    border:none !important;
  }
  #login .btn-login {
    font-family: "Prompt", sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 30px 10px 30px;
    border-radius: 4px;
    transition: 0.5s;
    color: var(--theme-bg-light);
    background: var(--theme-color-primary);
    box-shadow: 0 8px 28px var(--theme-color-primary);
    width: 100%;
    margin-bottom: 10px;
    border:none !important;
  }
  #login .animated {
    animation: up-down 2s ease-in-out infinite alternate-reverse both;
  }
  @media (max-width: 991px) {
    #login {
      /* height: 100vh; */
      padding: 15px 0 !important;
    }
    #login .animated {
      -webkit-animation: none;
      animation: none;
    }
    #login .login-img {
      text-align: center;
    }
    #login .login-img img {
      width: 50%;
    }
  }
  @media (max-width: 768px) {
    #login {
      /* height: auto; */
      padding: 15px 0 !important;
    }
    #login h1 {
      font-size: 28px;
      line-height: 36px;
    }
    #login h2 {
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 30px;
    }
    #login .login-img img {
      width: 70%;
    }
  }
  @media (max-width: 575px) {
    #login .login-img img {
      width: 80%;
    }
  }
  
  @-webkit-keyframes up-down {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
    }
  }
  
  @keyframes up-down {
    0% {
      transform: translateY(10px);
    }
    100% {
      transform: translateY(-10px);
    }
  }
  
  #login .icon-box {
    /* text-align: center; */
    border: 1px solid $white;
    padding: 20px 20px;
    transition: all ease-in-out 0.3s;
    background: $white;
    margin-bottom: 20px;
  }
  #login .icon-box .icon {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    transition: ease-in-out 0.3s;
  }
  #login .icon-box .icon i {
    color: var(--theme-color-action);
    font-size: 36px;
    transition: 0.3s;
  }
  #login .icon-box h4 {
    font-weight: 700;
    margin-bottom: 15px;
    font-size: 24px;
  }
  #login .icon-box h4 a {
    color: $gray-600;
    transition: ease-in-out 0.3s;
  }
  #login .icon-box p {
    line-height: 24px;
    font-size: 14px;
    margin-bottom: 0;
  }
  #login .icon-box:hover {
    border-color: $gray-300;
  }
  #login .icon-box:hover h4 a {
    color: var(--theme-color-action);
  }
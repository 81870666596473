#lotto-panel {
    margin-top: 75px;
    color: var(--theme-color-dark);
}

#lotto-panel .lotto-title{
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: var(--theme-color-tertiary);
    font-size: large;
    color: var(--theme-color-light);
}
#lotto-panel .lotto-des{
    padding-top: 5px;
    padding-bottom: 5px;
    background-color: var(--theme-color-light);
}
#lotto-panel .lotto-time{
    padding-top: 0.5px;
    padding-bottom: 7.5px;
    background-color: var(--theme-bg-light)
}
#lotto-panel .panel{
    padding: 20px 10px;
    background-color: var(--theme-opacity-dark);
}
#lotto-panel .panel .card{
    background-color: var(--theme-color-quinary);
    color:var(--theme-text-on-background);
}

#lotto-panel .nav-pills .nav-link.active{
    background-color: var(--theme-color-secondary);
    color:var(--theme-text-on-background);
}

#lotto-panel .nav-pills .nav-link {
    border: 1px var(--theme-color-secondary) solid;
    color: var(--theme-color-primary);
    border-radius: 0px;
}

#lotto-panel .btn {
    border-radius: 0px !important;
}

// #lotto-panel .bg-green-dark {
//     background-color: #0b2c31 !important;
// }

// #lotto-panel .bg-green-light {
//     background-color: #01736b !important;
// }

#lotto-panel .btn-outline-success:focus,
#lotto-panel .btn-outline-success:hover
{
    color: var(--theme-main-color-success);
    background-color: var(--theme-bg-light);
    border-color: var(--theme-main-color);
} 

#lotto-panel .btn-outline-success.active,
#lotto-panel .btn-outline-success.active
{
    color: var(--theme-text-on-background);
    background-color: var(--theme-main-color-success);
    border-color: var(--theme-main-color-success);
} 
#lotto-panel .btn-outline-warning:focus,
#lotto-panel .btn-outline-warning:hover
{
    color: var(--theme-main-color-warning);
    background-color: var(--theme-bg-light);
    border-color: var(--theme-main-color-warning);
} 

#lotto-panel .btn-outline-warning.active,
#lotto-panel .btn-outline-warning.active
{
    color: var(--theme-text-on-background);
    background-color: var(--theme-main-color-warning);
    border-color: var(--theme-main-color-warning);
} 

#lotto-panel .btn-outline-primary:focus,
#lotto-panel .btn-outline-primary:hover
{
    color:var(--theme-main-color-primary);
    background-color: var(--theme-bg-light);
    border-color: var(--theme-main-color-primary);
} 

#lotto-panel .btn-outline-primary.active,
#lotto-panel .btn-outline-primary.active
{
    color: var(--theme-text-on-background);
    background-color: var(--theme-main-color-primary);
    border-color: var(--theme-main-color-primary);
} 

#confirm-bill .btn {
    border-radius: 0px !important;
}

.btn-outline-warning {
    color:rgb(27, 27, 27) !important;
}

.btn-hide{
    display: none;
}


// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black:    #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue:    #0d6efd !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #198754 !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
) !default;
// scss-docs-end colors-map

// scss-docs-start theme-color-variables
$primary:       $blue !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;
// scss-docs-end theme-color-variables

.btn-warning {
  color: #000;
    /* background-color: #ffc107; */
    /* border-color: #ffc107; */
  background-image: var(--theme-gradient-tertiary);
}


.btn-primary {
  color: var(--theme-main-text-primary);
  background-color: var(--theme-main-color-primary);
  border-color: var(--theme-main-color-primary);
}
.btn-primary:hover {
  color: var(--theme-main-text-primary);
  background-color: var(--theme-main-color-primary-1);
  border-color: var(--theme-main-color-primary-2);
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: var(--theme-main-text-primary);
  background-color: var(--theme-main-color-primary-1);
  border-color: var(--theme-main-color-primary-2);
  box-shadow: 0 0 0 0.25rem var(--theme-main-color-primary-shadow);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: var(--theme-main-text-primary);
  background-color: var(--theme-main-color-primary-1);
  border-color: var(--theme-main-color-primary-2);
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem var(--theme-main-color-primary-shadow);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: var(--theme-main-text-primary);
  background-color: var(--theme-main-color-primary);
  border-color: var(--theme-main-color-primary);
}

.btn-secondary {
  color: var(--theme-main-text-secondary);
  background-color: var(--theme-main-color-secondary);
  border-color: var(--theme-main-color-secondary);
}
.btn-secondary:hover {
  color: var(--theme-main-text-secondary);
  background-color: var(--theme-main-color-secondary-1);
  border-color: var(--theme-main-color-secondary-2);
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: var(--theme-main-text-secondary);
  background-color: var(--theme-main-color-secondary-1);
  border-color: var(--theme-main-color-secondary-2);
  box-shadow: 0 0 0 0.25rem var(--theme-main-color-secondary-shadow);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: var(--theme-main-text-secondary);
  background-color: var(--theme-main-color-secondary-1);
  border-color: var(--theme-main-color-secondary-2);
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem var(--theme-main-color-secondary-shadow);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: var(--theme-main-text-secondary);
  background-color: var(--theme-main-color-secondary);
  border-color: var(--theme-main-color-secondary);
}

.btn-success {
  color: var(--theme-main-text-success);
  background-color: var(--theme-main-color-success);
  border-color: var(--theme-main-color-success);
}
.btn-success:hover {
  color: var(--theme-main-text-success);
  background-color: var(--theme-main-color-success-1);
  border-color: var(--theme-main-color-success-2);
}
.btn-check:focus + .btn-success, .btn-secondary:focus {
  color: var(--theme-main-text-success);
  background-color: var(--theme-main-color-success-1);
  border-color: var(--theme-main-color-success-2);
  box-shadow: 0 0 0 0.25rem var(--theme-main-color-success-shadow);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: var(--theme-main-text-success);
  background-color: var(--theme-main-color-success-1);
  border-color: var(--theme-main-color-success-2);
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem var(--theme-main-color-success-shadow);
}
.btn-success:disabled, .btn-success.disabled {
  color: var(--theme-main-text-success);
  background-color: var(--theme-main-color-success);
  border-color: var(--theme-main-color-success);
}


.btn-info {
  color: var(--theme-main-text-info);
  background-color: var(--theme-main-color-info);
  border-color: var(--theme-main-color-info);
}
.btn-info:hover {
  color: var(--theme-main-text-info);
  background-color: var(--theme-main-color-info-1);
  border-color: var(--theme-main-color-info-2);
}
.btn-check:focus + .btn-info, .btn-secondary:focus {
  color: var(--theme-main-text-info);
  background-color: var(--theme-main-color-info-1);
  border-color: var(--theme-main-color-info-2);
  box-shadow: 0 0 0 0.25rem var(--theme-main-color-info-shadow);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: var(--theme-main-text-info);
  background-color: var(--theme-main-color-info-1);
  border-color: var(--theme-main-color-info-2);
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem var(--theme-main-color-info-shadow);
}
.btn-info:disabled, .btn-info.disabled {
  color: var(--theme-main-text-info);
  background-color: var(--theme-main-color-info);
  border-color: var(--theme-main-color-info);
}


.btn-warning {
  color: var(--theme-main-text-warning);
  background-color: var(--theme-main-color-warning);
  border-color: var(--theme-main-color-warning);
}
.btn-warning:hover {
  color: var(--theme-main-text-warning);
  background-color: var(--theme-main-color-warning-1);
  border-color: var(--theme-main-color-warning-2);
}
.btn-check:focus + .btn-warning, .btn-secondary:focus {
  color: var(--theme-main-text-warning);
  background-color: var(--theme-main-color-warning-1);
  border-color: var(--theme-main-color-warning-2);
  box-shadow: 0 0 0 0.25rem var(--theme-main-color-warning-shadow);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: var(--theme-main-text-warning);
  background-color: var(--theme-main-color-warning-1);
  border-color: var(--theme-main-color-warning-2);
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem var(--theme-main-color-warning-shadow);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: var(--theme-main-text-warning);
  background-color: var(--theme-main-color-warning);
  border-color: var(--theme-main-color-warning);
}

.btn-danger {
  color: var(--theme-main-text-danger);
  background-color: var(--theme-main-color-danger);
  border-color: var(--theme-main-color-danger);
}
.btn-danger:hover {
  color: var(--theme-main-text-danger);
  background-color: var(--theme-main-color-danger-1);
  border-color: var(--theme-main-color-danger-2);
}
.btn-check:focus + .btn-danger, .btn-secondary:focus {
  color: var(--theme-main-text-danger);
  background-color: var(--theme-main-color-danger-1);
  border-color: var(--theme-main-color-danger-2);
  box-shadow: 0 0 0 0.25rem var(--theme-main-color-danger-shadow);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: var(--theme-main-text-danger);
  background-color: var(--theme-main-color-danger-1);
  border-color: var(--theme-main-color-danger-2);
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem var(--theme-main-color-danger-shadow);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: var(--theme-main-text-danger);
  background-color: var(--theme-main-color-danger);
  border-color: var(--theme-main-color-danger);
}


// .btn-light {
//   color: #000;
//   background-color: #f8f9fa;
//   border-color: #f8f9fa;
// }
// .btn-light:hover {
//   color: #000;
//   background-color: #f9fafb;
//   border-color: #f9fafb;
// }
// .btn-check:focus + .btn-light, .btn-light:focus {
//   color: #000;
//   background-color: #f9fafb;
//   border-color: #f9fafb;
//   box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
// }
// .btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
//   color: #000;
//   background-color: #f9fafb;
//   border-color: #f9fafb;
// }
// .btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
//   box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
// }
// .btn-light:disabled, .btn-light.disabled {
//   color: #000;
//   background-color: #f8f9fa;
//   border-color: #f8f9fa;
// }

// .btn-dark {
//   color: #fff;
//   background-color: #212529;
//   border-color: #212529;
// }
// .btn-dark:hover {
//   color: #fff;
//   background-color: #1c1f23;
//   border-color: #1a1e21;
// }
// .btn-check:focus + .btn-dark, .btn-dark:focus {
//   color: #fff;
//   background-color: #1c1f23;
//   border-color: #1a1e21;
//   box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
// }
// .btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
//   color: #fff;
//   background-color: #1a1e21;
//   border-color: #191c1f;
// }
// .btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
//   box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
// }
// .btn-dark:disabled, .btn-dark.disabled {
//   color: #fff;
//   background-color: #212529;
//   border-color: #212529;
// }

// .btn-outline-primary {
//   color: #0d6efd;
//   border-color: #0d6efd;
// }
// .btn-outline-primary:hover {
//   color: #fff;
//   background-color: #0d6efd;
//   border-color: #0d6efd;
// }
// .btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
//   box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
// }
// .btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
//   color: #fff;
//   background-color: #0d6efd;
//   border-color: #0d6efd;
// }
// .btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
//   box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
// }
// .btn-outline-primary:disabled, .btn-outline-primary.disabled {
//   color: #0d6efd;
//   background-color: transparent;
// }

// .btn-outline-secondary {
//   color: #6c757d;
//   border-color: #6c757d;
// }
// .btn-outline-secondary:hover {
//   color: #fff;
//   background-color: #6c757d;
//   border-color: #6c757d;
// }
// .btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
//   box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
// }
// .btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
//   color: #fff;
//   background-color: #6c757d;
//   border-color: #6c757d;
// }
// .btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
//   box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
// }
// .btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
//   color: #6c757d;
//   background-color: transparent;
// }

// .btn-outline-success {
//   color: var(--theme-main-color-success);
//   border-color: var(--theme-main-color-success);
// }
// .btn-outline-success:hover {
//   color: #fff;
//   background-color: var(--theme-main-color-success);
//   border-color: var(--theme-main-color-success);
// }
// .btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
//   box-shadow: 0 0 0 0.25rem var(--theme-main-color-success-shadow);
// }
// .btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
//   color: #fff;
//   background-color: var(--theme-main-color-success);
//   border-color: var(--theme-main-color-success);
// }
// .btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
//   box-shadow: 0 0 0 0.25rem var(--theme-main-color-success-shadow);
// }
// .btn-outline-success:disabled, .btn-outline-success.disabled {
//   color: var(--theme-main-color-success);
//   background-color: transparent;
// }

// .btn-outline-info {
//   color: #0dcaf0;
//   border-color: #0dcaf0;
// }
// .btn-outline-info:hover {
//   color: #000;
//   background-color: #0dcaf0;
//   border-color: #0dcaf0;
// }
// .btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
//   box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
// }
// .btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
//   color: #000;
//   background-color: #0dcaf0;
//   border-color: #0dcaf0;
// }
// .btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
//   box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
// }
// .btn-outline-info:disabled, .btn-outline-info.disabled {
//   color: #0dcaf0;
//   background-color: transparent;
// }

// .btn-outline-warning {
//   color: #ffc107;
//   border-color: #ffc107;
// }
// .btn-outline-warning:hover {
//   color: #000;
//   background-color: #ffc107;
//   border-color: #ffc107;
// }
// .btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
//   box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
// }
// .btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
//   color: #000;
//   background-color: #ffc107;
//   border-color: #ffc107;
// }
// .btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
//   box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
// }
// .btn-outline-warning:disabled, .btn-outline-warning.disabled {
//   color: #ffc107;
//   background-color: transparent;
// }

// .btn-outline-danger {
//   color: #dc3545;
//   border-color: #dc3545;
// }
// .btn-outline-danger:hover {
//   color: #fff;
//   background-color: #dc3545;
//   border-color: #dc3545;
// }
// .btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
//   box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
// }
// .btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
//   color: #fff;
//   background-color: #dc3545;
//   border-color: #dc3545;
// }
// .btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
//   box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
// }
// .btn-outline-danger:disabled, .btn-outline-danger.disabled {
//   color: #dc3545;
//   background-color: transparent;
// }

// .btn-outline-light {
//   color: #f8f9fa;
//   border-color: #f8f9fa;
// }
// .btn-outline-light:hover {
//   color: #000;
//   background-color: #f8f9fa;
//   border-color: #f8f9fa;
// }
// .btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
//   box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
// }
// .btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
//   color: #000;
//   background-color: #f8f9fa;
//   border-color: #f8f9fa;
// }
// .btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
//   box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
// }
// .btn-outline-light:disabled, .btn-outline-light.disabled {
//   color: #f8f9fa;
//   background-color: transparent;
// }

// .btn-outline-dark {
//   color: #212529;
//   border-color: #212529;
// }
// .btn-outline-dark:hover {
//   color: #fff;
//   background-color: #212529;
//   border-color: #212529;
// }
// .btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
//   box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
// }
// .btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
//   color: #fff;
//   background-color: #212529;
//   border-color: #212529;
// }
// .btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
//   box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
// }
// .btn-outline-dark:disabled, .btn-outline-dark.disabled {
//   color: #212529;
//   background-color: transparent;
// }

// .btn-link {
//   font-weight: 400;
//   color: #0d6efd;
//   text-decoration: underline;
// }
// .btn-link:hover {
//   color: #0a58ca;
// }
// .btn-link:disabled, .btn-link.disabled {
//   color: #6c757d;
// }

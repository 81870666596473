/* The following block can be included in a custom.scss */
@import "variables";
@import "variables_sys";
/* make the customizations */
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark
);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

@import "custom";
@import "login";
@import "register";
@import "wallet";
@import "shortcutmenu";
@import "award";
@import "gamelist";
@import "lottopanel";
@import "playhistory";
@import "menumobile";
@import "transaction";
@import "maintenance";
#register .logo-sexy {
    width: 50%;
}

#register .logo-sexy {
    width: 50%;
}

#register .form-d  {
    text-align: left !important;
    width: 65%;
    margin: auto;
    padding: auto;
}


#register .form-control {
    border-radius: 20px;
    background-color: var(--theme-opacity-primary);
    color: var(--theme-color-dark) !important;
    min-height: 50px;
    padding-left: 40px;
}
#register .form-control::placeholder {
    color: var(--theme-color-dark) !important;
}

#register .btn-bright {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 30px 10px 30px;
    border-radius: 20px;
    transition: 0.5s;
    color: var(--theme-text-out-background);
    background: var(--theme-color-primary);
    box-shadow: 0 8px 28px var(--theme-color-primary);
    min-width: 200px;
    margin-bottom: 10px;
    border:none !important;
}

#register .btn-bright-red {
    font-family: 'Prompt', sans-serif;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 8px 30px 10px 30px;
    border-radius: 4px;
    transition: 0.5s;
    color: var(--theme-text-on-background);
    background: var(--theme-color-six);
    box-shadow: 0 8px 28px var(--theme-color-six);
    margin-bottom: 10px;
    border: none !important;
}

#register .btn-disable {
    color: var(--theme-text-out-background);
    background: var(--theme-color-primary);
    box-shadow: 0 8px 28px var(--theme-color-primary);
}


@media only screen and (max-width: 800px) {
    #register .logo-sexy {
        width: 80%;
    }

    #register .form-d{
        width: 85%;
    }
    
}
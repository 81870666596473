#menu-mobile .modal-content {
    background-color:var(--theme-color-primary) !important;
    color: var(--theme-text-on-background);
}

#menu-mobile .modal-body {
    background-color:var(--theme-color-secondary) !important;
    // background-image: var(--theme-gradient-modal) !important;
}

#menu-mobile .title-box {
    background-color: var(--theme-color-tertiary) !important;
}

#menu-mobile .play-box {
    background: var(--theme-gradient-play) !important;
    color: var(--theme-color-play) !important;
}

#menu-mobile .icon-box {
    background-color: var(--theme-bg-light);
    color: $gray-900;
}

#menu-mobile a {
    color: var(--theme-text-on-background);
}
#menu-mobile a:hover {
    color: var(--theme-text-on-background);
}
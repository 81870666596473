#short-menu {
    margin: 10px 0;
}
#short-menu .icon-box {
    background: var(--theme-gradient-secondary);
    padding: 10px 5px;
    border-radius: 8px;
    margin-top: 10px;
    color: var(--theme-text-on-background);
    text-shadow: 0px 0px 10px var(--theme-color-primary);
}
#short-menu .play-box {
    height: 95% !important;
    color:var(--theme-text-on-background);
    text-shadow: 3px 3px 3px var(--theme-color-dark);
    background: var(--theme-gradient-play);
    vertical-align: middle !important;
    padding-top: 10%;
    font-size: 28pt;
}
#short-menu .play-box .icon {
    font-size: 42pt;
}

#short-menu  a {
    color: var(--theme-text-on-background) !important;
}

#short-menu .icon {
    font-size: 28pt;
}


@media only screen and (max-width:768px) {
    #short-menu .play-box {
        padding-top: 20px;
    }
}
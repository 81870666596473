#game-list {
    margin-top: 75px;
}
#game-list .game-panal{
    background: var(--theme-gradient-primary) ;
    padding: 10px 10px;
    border-radius: 8px;
    margin-top: 10px;

}
#game-list .game-title{
    text-align: left !important;
    padding: 2px;
    background: var(--theme-bg-btn-01);
    color: var(--theme-color-dark);
    max-width: 200px;
    border-radius: 10px;
}

#game-list .game-title img{
        width: 23px;
        float: left;
        margin-left: 10px;
        margin-right: 10px;
        vertical-align: middle;
}

#game-list .cover-box-huay2 {
    border-radius: 8px;
}

#game-list .cover-box-huay2 .cover-top-box-huay2 {
    border-radius: 8px  8px  0 0 ;
    background-position: right center;
    background-size: cover;
    padding: 10px 5px;
    min-height: 75px;
}

#game-list .cover-box-huay2 .h-vip {
    min-height: 95px;
}

#game-list .g-type {
    width: 100%;
    border-radius: 8px;
    border: 2px solid var(--theme-bg-light);
    margin-top: 10px;
    
}

#game-list .font-size-small {
    font-size: .6em;
}

#game-list .imgLotto{
    width: 45px;
     margin-top: 5px;
}

#game-list .font-zone{
    font-size: 11pt;
}

#game-list .count-down{
    background: var(--theme-bg-btn-01);
    padding: 5px;
    cursor: pointer;
    border-radius: 0 0 8px  8px;
    margin-bottom: 10px;
    font-size: smaller;
    color: var(--theme-color-dark) !important;
}
#game-list a {
    color: var(--theme-text-on-background) !important;
}

#game-list a:hover {
    color: var(--theme-text-on-background) !important;
}

/* @media only screen and (max-width: 768px) {
    #game-list .game-panal .col-sm-6 {
        flex: 0 0 auto;
        width: 100%;
    }
} */